// Base
$color-black:   #000 !default;
$color-white:   #fff !default;
$color-gray:    #828282 !default;

$color-spot-1:  #CC0000 !default; // Red

// Main
$color-base:	$color-white !default;

// Titles
$color-heading: $color-black !default;