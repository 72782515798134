// Container
.container {
  margin-right: auto;
  margin-left: auto;
  padding-right: ($grid-gutter-width / 2);
  padding-left: ($grid-gutter-width / 2);

  @include media($large-min) {
    max-width: $container-width;
  }
}

// Grid Row
.row {
  @include row();
}

// Grid Column
.col {
  @include col();
}