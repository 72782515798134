// Portfolio
.portfolio {
  padding: .5rem 0 1rem 0;

  .row {
    @include media($desktop-max) { 
      margin-right: -($grid-gutter-width-sm / 2);
      margin-left: -($grid-gutter-width-sm / 2);
    }
  }

  .col {
    margin-bottom: $grid-gutter-width-sm;

    @include media($desktop-max) { 
      padding-right: ($grid-gutter-width-sm / 2);
      padding-left: ($grid-gutter-width-sm / 2);
    }

    @include media($tablet-min) {   
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }

    @include media($desktop-min) {
      margin-bottom: $grid-gutter-width;
    }
  }

  // Item
  .portfolio-item {
    display: block;
    position: relative;
    height: 100%;
    background-color: $color-white;
    border-bottom: none;

    // Image
    &--image {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 17.5rem;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    // Title
    &--title {
      padding: 2.3rem 2rem 8rem 2rem;
    }

    // Designer
    &--designer {
      display: flex;
      align-items: center;
      position: absolute;
      left: 2rem;
      right: 2rem;
      bottom: 2rem;
      color: $color-black;
      font-size: $font-size-small;
      line-height: $line-height-small;

      img {
        width: 2.6rem;
        height: 2.6rem;
        border-radius: 50%;
        margin-right: 1rem;
      }
    }
  }

  // Extra items
  .portfolio-items--extra {
    display: none;
    margin-bottom: -.5rem;

    @include media($tablet-min) {
      margin-bottom: -1.5rem;
    }
  }

  // Button
  .portfolio-button {
    @extend .h2;
    border: 2px solid $color-white;
    color: $color-white;
    cursor: pointer;
    padding: 2rem;
    text-align: center;
    transition: all 250ms ease-out;

    &:hover {
      background-color: $color-white;
      color: $color-black;
    }
  }
}