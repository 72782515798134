// Main page
.mainpage {
  p {
    margin: 0 0 1.7rem 0;
  }

  // Block 'How we work'
  .block--we-work {
    .col {
      @include media($tablet-min) {   
        flex: 0 0 75%;
        max-width: 75%;
      }
    }
  }

  // Block 'Join us'
  .block--join-us {
    .col {
      @include media($tablet-min) {   
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
  }
}