// Header for Post
.header-post {
  border-bottom: 2px solid $color-white;
  margin-bottom: 2rem;
  padding: 1.5rem 0;

  @include media($tablet-min) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3.5rem;
    padding: 2.5rem 0 2rem 2rem;
    position: relative;
  }

  // Logo
  &--logo {
    display: block;
    width: 6.8rem;
    margin-bottom: .5rem;
    border-bottom: none;

    @include media($tablet-min) {
      width: 4.8rem;
      height: 1.6rem;
      margin-bottom: 0;
      position: absolute;
      top: 5.1rem;
      left: -1.6rem;
      line-height: 1rem;
      transform: rotate(-90deg);
    }
  }

  // Title - Design Squad
  &--title {
    @include media($tablet-min) {
      min-width: 100%;
    }
    
    @include media($desktop-min) {
      min-width: calc(100% - 225px);
    }

    .design-squad {
      margin-left: -1rem;

      @include media($mobile-tiny-min) {
        margin-left: -1.3rem;
      }

      @include media($tablet-min) {
        display: none;
      }
    }

    .design-squad-inline {
      @include media($tablet-max) {
        display: none;
      }
    }
  }

  // Social menu
  .menu-social {
    display: none;

    @include media($desktop-min) {
      display: flex;
    }
  }
}