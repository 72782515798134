// Headings
h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4 {
  margin: 0 0 1.2rem 0;
  font-weight: 700;
  color: $color-heading;

  @include media($tablet-min) {
    margin-bottom: 1.5rem;
  }
}

h1, .h1 {
  margin-bottom: 1.8rem;
  font-size: 2.8rem;
  line-height: 1.25;

  @include media($tablet-min) {
    margin-bottom: 3.5rem;
    font-size: 4.8rem;
    line-height: 1;
  }
}

h2, .h2 {
  font-size: 2.8rem;
  line-height: 1.25;

  @include media($tablet-min) {
    font-size: 3.6rem;
    line-height: 1;
  }
}

h3, .h3 {
  font-size: 2.4rem;
  line-height: 1.4;

  @include media($tablet-min) {
    font-size: 2.8rem;
    line-height: 1.25;
  }
}

h4, .h4 {
  font-size: 2rem;
  line-height: 1.5;

  @include media($tablet-min) {
    font-size: 2.4rem;
    line-height: 1.4;
  }
}

.title-large {
  margin-bottom: 1.8rem;
  padding-top: 1.5rem;
  font-size: 3.6rem;
  line-height: 1;

  @include media($tablet-min) {
    margin-bottom: 2rem;
    padding-top: 2rem;
    font-size: 7.2rem;
    line-height: 1;
  }
}