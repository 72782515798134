// Header for Main page
.header-main {
  border-bottom: 2px solid $color-white;
  padding-top: 1.5rem;

  @include media($tablet-min) {
    padding-top: 3rem;
  }

  // Header Top part
  .header-main--top {
    @include media($tablet-min) {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }
  }

  // Logo
  &--logo {
    display: block;
    width: 6.8rem;
    border-bottom: none;

    @include media($tablet-min) {
      width: auto;
    }
  }

  // Title - Design Squad
  &--title {
    margin-top: .5rem;
    margin-left: -1rem;

    @include media($mobile-tiny-min) {
      margin-left: -1.3rem;
    }

    @include media($tablet-min) {
      margin-left: -2.2rem;
    }
  }

  // Social menu
  .menu-social {
    display: none;

    @include media($desktop-min) {
      display: flex;
      margin-top: 5.3rem;
    }
  }

  // Header Bottom part
  .header-main--bottom {
    position: relative;

    // Team About
    .team--about {
      padding-top: 2rem;

      @include media($tablet-min) {
        max-width: 45%;
        padding: 8.5rem 0 4.5rem 0;
      }

      .title-large {
        margin-bottom: .7rem;
        padding-top: 0;
      }

      .button {
        margin-top: .5rem;

        @include media($mobile-tiny-max) {
          padding-left: 2rem;
          padding-right: 2rem;
        }

        @include media($tablet-max) {
          width: 100%;
        }

        @include media($tablet-min) {
          margin-top: 2rem;
        }
      }
    }

    // Team Photo
    .team--photo {
      height: 23rem;
      background-image: url('/i/designers.png?v=3');
      background-position: bottom center;
      background-repeat: no-repeat;
      background-size: contain;

      @include media($mobile-tiny-min) {
        height: 28rem;
      }

      @media (min-width: $mobile-min) and (max-width: 579px) {
        height: 37rem;
      }

      @media (min-width: 580px) {
        height: 44rem;
      }

      @include media($tablet-min) {
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 1;
        width: 60.5%;
        float: right;
      }

      // Retina display
      @media (-webkit-min-device-pixel-ratio: 2), 
      (min-resolution: 192dpi) {
        background-image: url('/i/designers@2x.png?v=3');
      }
    }
  }
  
}