// Clearfix
@mixin clearfix {
  zoom: 1;

  &:after,
  &:before {
    content: '';
    display: block;
    height: 0;
    overflow: hidden;
  }

  &:after {
    clear: both;
  }
}

// Media queries
@mixin media($size) {
  @media only screen and #{$size} {
    @content;
  }
}

// Button
@mixin button() {
  appearance: none;
  border: 0;
  cursor: pointer;
  display: inline-block;
  font-family: $font-family-base;
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 1.4;
  -webkit-font-smoothing: antialiased;
  padding: .8rem 3rem .9rem 3rem;
  text-align: center;
  text-decoration: none;
  transition: all 200ms ease-out;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;

  &,
  &:hover,
  &:focus {
    background-color: $color-black;
    color: $color-white;
  }
}

// Grid Row
@mixin row() {
  display: flex;
  flex-wrap: wrap;
  margin-right: -($grid-gutter-width / 2);
  margin-left: -($grid-gutter-width / 2);
}

// Grid Column
@mixin col() {
  position: relative;
  width: 100%;
  padding-right: ($grid-gutter-width / 2);
  padding-left: ($grid-gutter-width / 2);
}
