// Footer
#footer {
  padding: 4rem 0 3rem 0;

  @include media($tablet-min) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5rem 0;
  }

  // Copyright
  .copyright {
    margin-top: 3rem;

    @include media($tablet-max) {
      font-size: $font-size-base;
      line-height: $line-height-base;
    }

    @include media($tablet-min) {
      order: 1;
      margin-top: 0;
    }
  }

  // Social menu
  .menu-social {
    @include media($tablet-min) {
      order: 2;
    }
  }
}