// Menu Social (header/footer)
.menu-social {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 22.5rem;
  background-color: $color-black;

  li {
    position: relative;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;

    &:before {
      content: '';
      position: absolute;
      top: calc(50% - 7px);
      left: 0;
      width: .1rem;
      height: 1.3rem;
      border-left: 1px solid rgba(255, 255, 255, 0.2);
    }

    &:first-child {
      &:before {
        display: none;
      }
    }
  }

  a {
    display: block;
    width: 100%;
    min-width: 7.5rem;
    height: 5rem;
    margin: 0 auto;
    font-size: 0;
    line-height: 5rem;
    text-align: center;
    border-bottom: none;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: auto auto;

    &.menu-social--facebook {
      background-image: url('/i/icons/social_facebook.svg');
    }

    &.menu-social--instagram {
      background-image: url('/i/icons/social_instagram.svg');
    }

    &.menu-social--telegram {
      background-image: url('/i/icons/social_telegram.svg');
    }

    &.menu-social--youtube {
      background-image: url('/i/icons/social_youtube.svg');
    }
  }
}