// Projects links
.projects {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  border-bottom: 2px solid $color-white;
  margin-bottom: 2.5rem;
  padding: .5rem 0 1.5rem 0;

  @include media($tablet-min) {
    height: 10rem;
    padding: 0;
  }

  .projects-item {
    padding: 2.8rem 0;
    border-bottom: none;

    @include media($tablet-max) {
      flex: 0 0 50%;
      max-width: 50%;
      display: flex;
      justify-content: center;
      padding: 2.6rem 0;
    }

    img {
      vertical-align: bottom;
    }

    &:last-child {
      img {
        margin-bottom: .4rem;
      }
    }
  }

  a {
    border-bottom: none;
  }
}