// Post page
.post {
  padding: 2.2rem 2rem 3.2rem 2rem;
  background-color: $color-white;
  font-size: $font-size-base;
  line-height: $line-height-base;
  color: $color-black;

  @include media($tablet-min) {
    padding: 6rem 2rem 7.5rem 2rem;
  }

  > * {
    max-width: 68rem;
    margin-left: auto;
    margin-right: auto;
  }

  // Links
  a {
    color: $color-spot-1;
    border-bottom: none;

    &:hover {
      text-decoration: underline;
    }
  }

  // Lists
  ol,
  ul {
    margin-bottom: 1.1rem;
    padding-left: 2rem;

    ol,
    ul {
      margin-bottom: 0;
    }
  }

  ul {
    > li {
      &:before {
        content: '\2022';
        color: $color-spot-1;
        display: block;
        font-size: 2.5rem;
        max-width: 0;
        max-height: 0;
        position: relative;
        left: -2rem;
        top: -.6rem;
      }
    }
  }

  // Tiltes
  h2,
  h3,
  h4 {
    padding-top: 1rem;
  }

  h1,
  .post--media {
    max-width: 91.5rem;
    text-align: center;
  }

  // Post Media (image/video)
  .post--media {
    margin: 1.5rem -2rem 1.5rem -2rem;

    @include media($tablet-min) {
      margin: 2rem auto 3rem auto;
    }
  
    iframe {
      max-width: 100%;
    }
  }

  // Post Meta
  .post--meta {
    margin-bottom: 1.2rem;
    line-height: 1.4;
    text-align: center;

    @include media($tablet-min) {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin-bottom: 2.5rem;
    }

    &__photo {
      margin-bottom: .4rem;
      
      @include media($tablet-min) {
        margin-bottom: 0;
      }
      
      img {
        width: 2.6rem;
        height: 2.6rem;
        border-radius: 50%;
      }
    }

    &__name {
      margin-left: 1rem;
      font-weight: bold;
    }

    &__date {
      margin-left: .8rem;
      color: $color-gray;

      .dot {
        @include media($tablet-max) {
          display: none;
        }
      }
    }
  }

  // Post Social links
  .post--social {
    margin-top: 1.8rem;
  }
}