// Font family
$font-family-base: 'Helvetica Neue', Helvetica, sans-serif;

// Font sizes
$font-size-large:  2.4rem;
$font-size-base:   1.6rem;
$font-size-small:  1.4rem;

// Line height
$line-height-large: 1.4;
$line-height-base:  1.5;
$line-height-small: 1.2;