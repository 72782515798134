// Jobs
.jobs {
  padding-top: 1.5rem;
  border-bottom: 2px solid $color-white;

  @include media($tablet-min) {
    padding-top: 3rem;
  }

  .jobs-item {
    display: block;
    padding: 1.8rem 6rem .5rem 0;
    border-top: 2px solid $color-white;
    border-bottom: none;
    background-image: url('/i/icons/arrow-black.svg');
    background-position: center right;
    background-repeat: no-repeat;
    background-size: 4rem 4rem;

    @include media($tablet-min) {
      padding-top: 2.5rem;
    }

    .h1,
    .h4 {
      color: $color-white;
    }

    .h1 {
      display: inline;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
      transition: all 200ms ease-out;
    }

    .h4 {
      margin-top: .8rem;
      font-weight: normal;

      @include media($tablet-min) {
        margin-top: 1.3rem;
      }
    }

    &:hover {
      background-image: url('/i/icons/arrow-white.svg');

      .h1 {
        border-bottom-color: $color-white;
      }
    }
  }
  
}