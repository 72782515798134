// Reboot styles
html {
  font-size: 62.5%;
}

body,
html {
  line-height: 1.1;
  margin: 0;
  padding: 0;
}

body {
  background-color: $color-spot-1;
  color: $color-base;
  font-family: $font-family-base;
  font-size: 2rem;
  line-height: 1.5;
  overflow-x: hidden;

  @include media($tablet-min) {
    font-size: $font-size-large;
    line-height: $line-height-large;
  }
}

* {
  box-sizing: border-box;

  &:focus {
    outline: none;
  }
}

// Paragraphs
p {
  margin: 0 0 1.1rem 0;
}

// Links
a {
  color: $color-white;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  transition: all 200ms ease-out;

  &:hover {
    border-bottom-color: $color-white;
    text-decoration: none;
  }
}

// Images
img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  border: 0;
}

// Buttons
.button {
  @include button();
}