// Breakpoints
$mobile-tiny-min: '(min-width: 375px)';
$mobile-min:      '(min-width: 480px)';
$tablet-min:      '(min-width: 768px)';
$desktop-min:     '(min-width: 960px)';
$large-min:       '(min-width: 1070px)';

$mobile-tiny-max: '(max-width: 374px)';
$mobile-max:      '(max-width: 479px)';
$tablet-max:      '(max-width: 767px)';
$desktop-max:     '(max-width: 959px)';
$large-max:       '(max-width: 1069px)';

// Container
$container-width:       1060px;

// Grid
$grid-gutter-width:     30px !default;
$grid-gutter-width-sm:  ($grid-gutter-width / 2) !default;